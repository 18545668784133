import React, { createRef, RefObject, useEffect } from 'react';

import { cn } from '../../utils';
import { animateOnScreenEntry } from '../../utils/animations';
import Heading from '../Typography/Heading/Heading';
import Paragraph from '../Typography/Paragraph/Paragraph';
import * as styles from './TitleParagraphUnderline.module.scss';
import TitleParagraphUnderlineU from './TitleParagraphUnderline.type';

const TitleParagraphUnderline = (props: TitleParagraphUnderlineU) => {
    const ref = createRef<HTMLElement>();
    useEffect(() => {
        const animateFn = props.animateFunctions?.animateLine;
        if (animateFn && ref.current) {
            animateOnScreenEntry(animateFn, ref.current);
        }
    }, []);
    return (
        <div className={cn(styles.title, props.className)}>
            <Heading
                level={props.headingLevel}
                animateFn={props.animateFunctions?.animateHeading}
            >
                {props.heading}
            </Heading>
            <Paragraph
                size={props.textSize}
                animateFn={props.animateFunctions?.animateParagraph}
            >
                {props.text}
            </Paragraph>
            {props.underline && (
                <div
                    ref={ref as RefObject<HTMLDivElement>}
                    className={styles[props.underline + "Underline"]}
                    style={{
                        transform: props.animateFunctions?.animateLine
                            ? "scaleX(0)"
                            : "0",
                    }}
                />
            )}
        </div>
    );
};

export default TitleParagraphUnderline;

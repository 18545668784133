// extracted by mini-css-extract-plugin
export var closed = "PortfoliosArchiveNew-module--closed--0ab4c";
export var container = "PortfoliosArchiveNew-module--container--8b78e";
export var link = "PortfoliosArchiveNew-module--link--b1d76";
export var linkAbsolute = "PortfoliosArchiveNew-module--linkAbsolute--85682";
export var oldPortfolio = "PortfoliosArchiveNew-module--oldPortfolio--408ec";
export var oldPortfolioBox = "PortfoliosArchiveNew-module--oldPortfolioBox--0cc01";
export var oldPortfolios = "PortfoliosArchiveNew-module--oldPortfolios--df5d6";
export var portfolios = "PortfoliosArchiveNew-module--portfolios--1b946";
export var title = "PortfoliosArchiveNew-module--title--aa51f";
export var titleUnderline = "PortfoliosArchiveNew-module--titleUnderline--4a22e";
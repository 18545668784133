import React, { createRef, useEffect, useState } from 'react';

import * as styles from './ChartSvg.module.scss';
import IChartSvg from './ChartSvg.type';

const ChartSvg = (props: IChartSvg) => {
    const rr = createRef<SVGPathElement>(); // real estate path ref
    const sr = createRef<SVGPathElement>(); // stocks path ref
    const [rStrokeD, setRStrokeD] = useState(0); // real estate path dash offset
    const [sStrokeD, setSStrokeD] = useState(0); // stocks path dash offset
    useEffect(() => {
        setRStrokeD(rr.current?.getTotalLength() || 0);
        setSStrokeD(sr.current?.getTotalLength() || 0);
    }, []);
    return (
        <svg
            width="555"
            height="354"
            viewBox="0 0 555 354"
            fill="none"
            className={styles.svg}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path opacity="0.1" d="M1 282.859H554" stroke="#232323" />
            <path opacity="0.1" d="M1 247.898H554" stroke="#232323" />
            <path opacity="0.1" d="M1 212.938H554" stroke="#232323" />
            <path opacity="0.1" d="M1 176.77H554" stroke="#232323" />
            <path opacity="0.1" d="M1 141.809H554" stroke="#232323" />
            <path opacity="0.1" d="M1 106.848H554" stroke="#232323" />
            <path opacity="0.1" d="M1 70.6816H554" stroke="#232323" />
            <path opacity="0.1" d="M1 35.7188H554" stroke="#232323" />
            <path opacity="0.1" d="M1 0.757812H554" stroke="#232323" />
            <path opacity="0.1" d="M1 319.025H554" stroke="#232323" />
            <path opacity="0.1" d="M1 352.658H554" stroke="#232323" />
            <g mask="url(#mask)">
                <path
                    d="M2 314.84L28.758 321.44L56.4632 332L83.458 321.44L111.163 317.48L138.395 314.84L165.863 305.16L193.332 301.64L220.563 327.16L247.795 315.28L275.026 306.92H302.258L329.963 295.92L357.668 272.6L384.663 259.4L411.895 257.64L439.363 244.44L466.832 218.04L494.063 224.64L521.295 179.76L549 145"
                    ref={sr}
                    strokeWidth="2"
                    stroke="#1F1F1F"
                    strokeDasharray="2 2"
                />
            </g>
            <path
                d="M1 309L28.0632 304.702L55.845 300.404L84.1057 288.943L111.648 273.183L138.711 255.991L166.493 237.844L194.275 223.04L222.056 261.244L249.359 255.991L277.38 227.815L304.922 205.847L332.704 189.61L360.725 169.553L388.028 138.989L415.57 112.246L443.352 96.009L471.134 81.6822L498.437 63.535L526.458 43L554 56.3716"
                ref={rr}
                style={
                    props.animStarted
                        ? {
                            transition: `stroke-dashoffset ${props.animationTime}s`,
                            opacity: "",
                            strokeDasharray: rStrokeD,
                            strokeDashoffset: "0",
                        }
                        : {
                            transition: "",
                            opacity: "0",
                            strokeDasharray: rStrokeD,
                            strokeDashoffset: rStrokeD,
                        }
                }
                stroke="#FF4E00"
                strokeWidth="2"
            />
            <defs>
                <mask id="mask">
                    <path
                        d="M2 314.84L28.758 321.44L56.4632 332L83.458 321.44L111.163 317.48L138.395 314.84L165.863 305.16L193.332 301.64L220.563 327.16L247.795 315.28L275.026 306.92H302.258L329.963 295.92L357.668 272.6L384.663 259.4L411.895 257.64L439.363 244.44L466.832 218.04L494.063 224.64L521.295 179.76L549 145"
                        strokeWidth="2"
                        stroke="#fff"
                        style={
                            props.animStarted
                                ? {
                                    transition: `stroke-dashoffset ${props.animationTime}s`,
                                    opacity: "",
                                    strokeDasharray: sStrokeD,
                                    strokeDashoffset: "0",
                                }
                                : {
                                    transition: "",
                                    opacity: "0",
                                    strokeDasharray: sStrokeD,
                                    strokeDashoffset: sStrokeD,
                                }
                        }
                    />
                </mask>
            </defs>
        </svg>
    );
};

export default ChartSvg;

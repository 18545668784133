import React from 'react';

import AnimatedCounter from '../AnimatedCounter/AnimatedCounter';
import Icon from '../Icon/Icon';
import Paragraph from '../Typography/Paragraph/Paragraph';
import * as styles from './StatisticsCounter.module.scss';
import IStatisticsCounter from './StatisticsCounter.type';

const StatisticsCounter = (props: IStatisticsCounter) => (
    <li>
        <div className={styles.stat}>
            <div>
                <Paragraph size="large" className={styles.name}>
                    {props.name}
                </Paragraph>
                <h2 className={styles.value}>
                    <AnimatedCounter
                        targetNumber={props.counterTarget}
                        beginningNumber={props.countFrom}
                        animationDuration={3}
                    />
                    {props.counterText}
                </h2>
            </div>
            <Icon {...props.icon} width={74} height={74}/>
        </div>
    </li>
);

export default StatisticsCounter;

import { Link } from "gatsby";
import React from "react";

import Image from "../../components/Image/Image";
import TitleUnderlineParagraph from "../../components/TitleUnderlineParagraph/TitleUnderlineParagraph";
import { cn } from "../../utils";
import * as styles from "./PortfoliosArchiveNew.module.scss";
import IPortfoliosArchiveNew from "./PortfoliosArchiveNew.type";

const PortfoliosArchiveNew = (props: IPortfoliosArchiveNew) => {
    return (
        <section>
            <div className={cn("container", styles.container)}>
                <TitleUnderlineParagraph
                    heading={props.pageTitle}
                    headingLevel="h1"
                    underline="center"
                    className={styles.title}
                />
                <div className={styles.portfolios}>
                    <div className={styles.oldPortfolios}>
                        {props.children.map((portfolio) => (
                            <div
                                key={portfolio.title}
                                className={styles.oldPortfolio}
                            >
                                <Link
                                    to={portfolio.uri}
                                    className={styles.linkAbsolute}
                                ></Link>
                                <Image
                                    {...portfolio.featuredImage.node}
                                    className={styles.featuredImage}
                                />
                                <div className={styles.oldPortfolioBox}>
                                    <span className={styles.closed}>
                                        {`Closed - ${portfolio.sections.sections[0].closed}`}
                                    </span>
                                    <TitleUnderlineParagraph
                                        heading={portfolio.title}
                                        headingLevel="h2"
                                        className={styles.titleUnderline}
                                        text={
                                            portfolio.sections.sections[0]
                                                .shortDescription
                                        }
                                        textSize="small"
                                        underline="left"
                                    />
                                    <Link
                                        to={portfolio.uri}
                                        className={styles.link}
                                    >
                                        Investments
                                    </Link>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default PortfoliosArchiveNew;

// extracted by mini-css-extract-plugin
export var container = "StatsAndCredits-module--container--415d6";
export var heading = "StatsAndCredits-module--heading--38e34";
export var image = "StatsAndCredits-module--image--ad64e";
export var logo = "StatsAndCredits-module--logo--dc208";
export var logos = "StatsAndCredits-module--logos--9c8f8";
export var logosWrapper = "StatsAndCredits-module--logosWrapper--40c92";
export var row = "StatsAndCredits-module--row--57b30";
export var scroll = "StatsAndCredits-module--scroll--5943e";
export var section = "StatsAndCredits-module--section--9aa25";
export var stats = "StatsAndCredits-module--stats--f9317";
import React from "react";

import Image from "../../components/Image/Image";
import StatisticsCounter from "../../components/StatisticsCounter/StatisticsCounter";
import StockChart from "../../components/StockChart/StockChart";
import * as styles from "./StatsAndCredits.module.scss";
import IStatsAndCredits from "./StatsAndCredits.type";
import Heading from "../../components/Typography/Heading/Heading";

const StatsAndCredits = (props: IStatsAndCredits) => (
    <section className={styles.section}>
        <div className={styles.logosWrapper}>
            <ul className={styles.logos}>
                {props.logos.map((logo, i) => (
                    <li key={i} className={styles.logo}>
                        <Image
                            {...logo.logo}
                            className={styles.image}
                            sizes="(min-width: 1440px) 259px, 20vw"
                        />
                    </li>
                ))}
                {props.logos.map((logo, i) => (
                    <li key={i + logo.logo.altText} className={styles.logo}>
                        <Image
                            {...logo.logo}
                            className={styles.image}
                            sizes="(min-width: 1440px) 259px, 20vw"
                        />
                    </li>
                ))}
            </ul>
        </div>

        <div className={styles.container}>
            {props.logosTitle && (
                <Heading
                    level="h2"
                    underline="center"
                    className={styles.heading}
                >
                    {props.logosTitle}
                </Heading>
            )}
            <div className={styles.row}>
                <ul className={styles.stats}>
                    {props.stats.map((stat) => (
                        <StatisticsCounter key={stat.name} {...stat} />
                    ))}
                </ul>
                <StockChart />
            </div>
        </div>
    </section>
);

export default StatsAndCredits;

// extracted by mini-css-extract-plugin
export var buttonFW = "VideoPlayer-module--buttonFW--78d33";
export var container = "VideoPlayer-module--container--ad1da";
export var hideDesktop = "VideoPlayer-module--hideDesktop--bf271";
export var hideMobile = "VideoPlayer-module--hideMobile--1de39";
export var iframe = "VideoPlayer-module--iframe--0f2e6";
export var iframe__title = "VideoPlayer-module--iframe__title--94e15";
export var loaded = "VideoPlayer-module--loaded--8c569";
export var pattern = "VideoPlayer-module--pattern--1819c";
export var playButton = "VideoPlayer-module--playButton--36a24";
export var player = "VideoPlayer-module--player--35911";
export var shadow = "VideoPlayer-module--shadow--c14c4";
export var thumbnail = "VideoPlayer-module--thumbnail--91d77";
export var wrapper = "VideoPlayer-module--wrapper--9f61c";
export var wrapperFW = "VideoPlayer-module--wrapperFW--3d399";
import React, { createRef, useEffect, useMemo, useRef } from "react";

import Pattern from "../../images/pattern.png";
import PlayButton from "../PlayButton/PlayButton";
import * as styles from "./VideoPlayer.module.scss";
import IVideoPlayer from "./VideoPlayer.type";

//@ts-ignore
import lazyframe from "lazyframe";
import { cn } from "../../utils";

const VideoPlayer = (props: IVideoPlayer) => {
    const thumbnailMobileImg = props.thumbnailMobile
        ? props.thumbnailMobile.localFile.childImageSharp.gatsbyImageData
        : props.thumbnail.localFile.childImageSharp.gatsbyImageData;
    const thumbnailImageData = useMemo(
        () => props.thumbnail.localFile.childImageSharp.gatsbyImageData,
        []
    );
    const thumbnailImageDataMobile = useMemo(() => thumbnailMobileImg, []);
    const [videoLoaded, setVideoLoaded] = React.useState(false);

    const { videoUrl } = props;

    const ref = createRef<any>();

    const refVideo = useRef<HTMLVideoElement>(null);

    useEffect(() => {
        if (!refVideo.current) {
            return;
        }

        refVideo.current.defaultMuted = true;
        refVideo.current.muted = true;
    }, []);

    useEffect(() => {
        let isMounted = true;
        if (isMounted) {
            setTimeout(() => {
                videoUrl && setVideoLoaded(true);
            }, 1000);
            setTimeout(() => {
                !videoUrl && ref.current && lazyframe(ref.current);
            }, 3000);
        }
        return () => {
            isMounted = false;
        };
    }, []);

    return (
        <div
            className={cn(
                styles.wrapper,
                props.fullWidthVideo ? styles.wrapperFW : ""
            )}
            onClick={props.onPlayBtnClick}
        >
            {!props.fullWidthVideo && <div className={styles.shadow}></div>}
            <div className={styles.player}>
                {!videoUrl ? (
                    <div
                        className={cn(styles.iframe, styles.loaded)}
                        data-src={`https://vimeo.com/${props.thumbnailVideoId}?controls=0&autoplay=1&loop=1&muted=1`}
                        data-vendor="vimeo"
                        data-ratio="1:1"
                        data-lazyload="true"
                        data-initinview="true"
                        data-loop="true"
                        ref={ref}
                    >
                        {thumbnailImageData.images.sources && (
                            <picture className={styles.thumbnail}>
                                <source
                                    {...thumbnailImageData.images.sources[0]}
                                />
                                <img
                                    {...thumbnailImageData.images.fallback}
                                    alt={props.thumbnail.altText}
                                />
                            </picture>
                        )}
                    </div>
                ) : (
                    <div className={cn(styles.iframe, styles.loaded)}>
                        {thumbnailImageData.images.sources && (
                            <picture
                                className={cn(
                                    styles.thumbnail,
                                    styles.hideMobile
                                )}
                            >
                                <source
                                    {...thumbnailImageData.images.sources[0]}
                                />
                                <img
                                    {...thumbnailImageData.images.fallback}
                                    alt={props.thumbnail.altText}
                                />
                            </picture>
                        )}
                        {thumbnailImageDataMobile.images.sources && (
                            <picture
                                className={cn(
                                    styles.thumbnail,
                                    styles.hideDesktop
                                )}
                            >
                                <source
                                    {...thumbnailImageDataMobile.images
                                        .sources[0]}
                                />
                                <img
                                    {...thumbnailImageDataMobile.images
                                        .fallback}
                                    alt={props.thumbnail.altText}
                                />
                            </picture>
                        )}
                        {videoLoaded && (
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: `<video autoplay loop muted playsinline><source src=${videoUrl} type="video/mp4"/></video>`,
                                }}
                            />
                        )}
                    </div>
                )}
            </div>
            <PlayButton
                className={cn(
                    styles.playButton,
                    props.fullWidthVideo ? styles.buttonFW : ""
                )}
                white={props.fullWidthVideo}
                onClick={props.onPlayBtnClick}
            />
            {!props.fullWidthVideo && (
                <div
                    style={{ backgroundImage: `url(${Pattern})` }}
                    className={styles.pattern}
                />
            )}
        </div>
    );
};

export default VideoPlayer;

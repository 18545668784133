import loadable, { LoadableComponent } from "@loadable/component";
// import AdvantagesSlider from '../sections/AdvantagesSlider/AdvantagesSlider';
// import AlternatingGallery from '../sections/AlternatingGallery/AlternatingGallery';
// import CallToActionLinks from '../sections/CallToActionLinks/CallToActionLinks';
import Hero from "../sections/Hero/Hero";
import HeroWithForm from "../sections/HeroWithForm/HeroWithForm";
import HeroWithImage from "../sections/HeroWithImage/HeroWithImage";
import HeroWithVideoThumbnailAndTitle from "../sections/HeroWithVideoThumbnailAndTitle/HeroWithVideoThumbnailAndTitle";
import PortfoliosArchiveNew from "../sections/PortfoliosArchiveNew/PortfoliosArchiveNew";
// import Investments from '../sections/Investments/Investments';
// import LegalPage from '../sections/LegalPage/LegalPage';
// import NoSection from '../sections/NoSection/NoSection';
// import Partners from '../sections/Partners/Partners';
// import PortfoliosArchive from '../sections/PortfoliosArchive/PortfoliosArchive';
// import PostContent from '../sections/PostContent/PostContent';
import StatsAndCredits from "../sections/StatsAndCredits/StatsAndCredits";
import Countdown from "../sections/Countdown/Countdown";

// import TeamMembers from '../sections/TeamMembers/TeamMembers';
// import TestimonialVideos from '../sections/TestimonialVideos/TestimonialVideos';
// import TextAndImage from '../sections/TextAndImage/TextAndImage';

const AdvantagesSlider = loadable(
    () => import("../sections/AdvantagesSlider/AdvantagesSlider")
);
const AlternatingGallery = loadable(
    () => import("../sections/AlternatingGallery/AlternatingGallery")
);
const CallToActionLinks = loadable(
    () => import("../sections/CallToActionLinks/CallToActionLinks")
);
const Investments = loadable(
    () => import("../sections/Investments/Investments")
);
const LegalPage = loadable(() => import("../sections/LegalPage/LegalPage"));
const NoSection = loadable(() => import("../sections/NoSection/NoSection"));
const Partners = loadable(() => import("../sections/Partners/Partners"));
const PortfoliosArchive = loadable(
    () => import("../sections/PortfoliosArchive/PortfoliosArchive")
);
const PostContent = loadable(
    () => import("../sections/PostContent/PostContent")
);
const TeamMembers = loadable(
    () => import("../sections/TeamMembers/TeamMembers")
);
const TestimonialVideos = loadable(
    () => import("../sections/TestimonialVideos/TestimonialVideos")
);
const TextAndImage = loadable(
    () => import("../sections/TextAndImage/TextAndImage")
);
const PropertiesBoxAndDescription = loadable(
    () =>
        import(
            "../sections/PropertiesBoxAndDescription/PropertiesBoxAndDescription"
        )
);
const TwoBoxes = loadable(() => import("../sections/TwoBoxes/TwoBoxes"));
const JobOffers = loadable(() => import("../sections/JobOffers/JobOffers"));

const getSectionComponent = (
    name: string
): ((props: any) => JSX.Element) | LoadableComponent<any> => {
    const sectionName = name?.split("_").pop() || "";
    switch (sectionName) {
        case "HeroWithVideoThumbnailAndTitle":
            return HeroWithVideoThumbnailAndTitle;
        case "StatsAndCredits":
            return StatsAndCredits;
        case "TestimonialVideos":
            return TestimonialVideos;
        case "TextAndImage":
            return TextAndImage;
        case "CallToActionLinks":
            return CallToActionLinks;
        case "AdvantagesSlider":
            return AdvantagesSlider;
        case "HeroWithForm":
            return HeroWithForm;
        case "Investments":
            return Investments;
        case "HeroWithImage":
            return HeroWithImage;
        case "PortfoliosArchive":
            return PortfoliosArchive;
        case "PortfoliosArchiveNew":
            return PortfoliosArchiveNew;
        case "Hero":
            return Hero;
        case "AlternatingGallery":
            return AlternatingGallery;
        case "TeamMembers":
            return TeamMembers;
        case "Partners":
            return Partners;
        case "PostContent":
            return PostContent;
        case "LegalPage":
            return LegalPage;
        case "PropertiesBoxAndDescription":
            return PropertiesBoxAndDescription;
        case "Countdown":
            return Countdown;
        case "TwoBoxes":
            return TwoBoxes;
        case "JobOffers":
            return JobOffers;
        default:
            return NoSection;
    }
};

export default getSectionComponent;

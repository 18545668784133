import gsap from "gsap";
import React, { useState } from "react";

import PopupVideo from "../../components/PopupVideo/PopupVideo";
import TitleParagraphUnderline from "../../components/TitleParagraphUnderline/TitleParagraphUnderline";
import * as styles from "./HeroWithVideoThumbnailAndTitle.module.scss";
import IHeroWithVideoThumbnailAndTitle from "./HeroWithVideoThumbnailAndTitle.type";
import Heading from "../../components/Typography/Heading/Heading";
import VideoPlayer from "../../components/VideoPlayer/VideoPlayer";
import { cn } from "../../utils";

const HeroWithVideoThumbnailAndTitle = (
    props: IHeroWithVideoThumbnailAndTitle
) => {
    const [showPopup, setShowPopup] = useState(false);
    return (
        <>
            <section
                id={props.id}
                className={cn(
                    styles.container,
                    props.fullWidthVideo ? styles.containerFW : ""
                )}
            >
                <div className={styles.leftColumn}>
                    <VideoPlayer
                        thumbnail={props.videoThumbnail}
                        thumbnailMobile={props.videoThumbnailMobile}
                        thumbnailVideoId={props.thumbnailVideoId}
                        fullWidthVideo={props.fullWidthVideo}
                        altText={props.videoThumbnail?.altText}
                        onPlayBtnClick={() => {
                            setShowPopup(true);
                        }}
                        videoUrl={props.thumbnailVideo?.mediaItemUrl}
                    />
                </div>
                {!props.fullWidthVideo ? (
                    <div className={styles.rightColumn}>
                        <TitleParagraphUnderline
                            className={styles.title}
                            heading={props.title}
                            text={props.subtitle}
                            textSize="large"
                            underline="right"
                            animateFunctions={{
                                animateHeading,
                                animateParagraph,
                                animateLine,
                            }}
                        />
                    </div>
                ) : (
                    <Heading
                        level="h1"
                        underline="no"
                        className={styles.heading}
                    >
                        {props.title}
                    </Heading>
                )}
            </section>
            <PopupVideo
                showPopup={showPopup}
                closePopupFn={() => {
                    setShowPopup(false);
                }}
                videoId={props.popupVideoId}
            />
        </>
    );
};

export default HeroWithVideoThumbnailAndTitle;

const animateHeading = (elem: HTMLElement) => {
    gsap.from(elem, { y: -100, duration: 1 });
    elem.style.opacity = "";
};
const animateParagraph = (elem: HTMLElement) => {
    setTimeout(() => {
        gsap.from(elem, { y: -70, duration: 0.75 });
        elem.style.opacity = "";
    }, 750);
};
const animateLine = (elem: HTMLElement) => {
    const animationTime = 1.5;
    elem.style.transition = animationTime + "s";
    elem.style.transform = "scaleY(1)";

    setTimeout(() => {
        elem.style.transition = "";
    }, animationTime * 1000);
};

import React, { createRef, useEffect, useState } from 'react';

import { animateOnScreenEntry } from '../../utils/animations';
import Paragraph from '../Typography/Paragraph/Paragraph';
import ChartSvg from './ChartSvg/ChartSvg';
import * as styles from './StockChart.module.scss';

const animationTime = 4.5;

const StockChart = () => {
    const ref = createRef<HTMLDivElement>();

    const [animStarted, setAnimStarted] = useState(false);

    useEffect(() => {
        const animateChartFn = () => {
            setAnimStarted(true);
        };
        if (ref.current) animateOnScreenEntry(animateChartFn, ref.current, 0.8);
    }, []);
    return (
        <div className={styles.element} ref={ref}>
            <Paragraph size="xsmall" className={styles.caption}>
                Commercial Real Estate vs Stock Market - Growth of $100K from
                2000 through 2020
            </Paragraph>
            <div className={styles.yLabels}>
                {yLabels.map((label) => <span key={label}>{label}</span>)}
            </div>
            <div className={styles.chart}>
                <ChartSvg
                    animationTime={animationTime}
                    animStarted={animStarted}
                />
            </div>
            <div className={styles.xLabels}>
                {xLabels.map((label) => <span key={label}>{label}</span>)}
            </div>
            <div className={styles.legend}>
                <span>Real Estate</span>
                <span>Stocks</span>
            </div>
            <Paragraph size="xsmall" className={styles.caption}>
                {`Real Estate is tracked by the Commercial Property Return Index
                from <span>Green&nbsp;Street&nbsp;Advisors.</span><br>Stocks represented by SPDR S&P 500
                ETF Trust.`}
            </Paragraph>
        </div>
    );
};

export default StockChart;

const yLabels = ["$900K", "$700K", "$600K", "$400K", "$200K", "$0"];
const xLabels = [
    "2000",
    "'01",
    "'04",
    "'07",
    "'10",
    "'13",
    "'16",
    "'19",
    "'20",
];

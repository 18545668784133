import gsap from "gsap";
import React, { useEffect, useRef, useState } from "react";

import { animateOnScreenEntry } from "../../utils/animations";
import * as styles from "./AnimatedCounter.module.scss";
import IAnimatedCounter from "./AnimatedCounter.type";

const AnimatedCounter = (props: IAnimatedCounter) => {
    const ref = useRef<HTMLSpanElement>(null);
    const [spanWidth, setSpanWidth] = useState<number>(
        props.beginningNumber?.toString().length || 1
    );

    const [number, setNumber] = useState<number | null>(
        props.beginningNumber || 0
    );
    const [animationComplete, setAnimationComplete] = useState<boolean>(false);

    useEffect(() => {
        if (ref.current) {
            animateOnScreenEntry((el) => {
                setTimeout(() => {
                    gsap.to(el, {
                        textContent: props.targetNumber,
                        duration: props.animationDuration,
                        ease: "power1.in",
                        snap: { textContent: 1 },
                        stagger: {
                            each: 1.0,
                        },
                        onUpdate: function () {
                            const currentNumber = this.targets()[0].textContent;

                            setSpanWidth(currentNumber.toString().length);
                            setNumber(null);
                            if (
                                currentNumber == props.targetNumber - 1 ||
                                currentNumber == props.targetNumber + 1
                            )
                                setAnimationComplete(true);
                        },
                    });
                }, 500);
            }, ref.current);
        }
    }, []);

    return (
        <span
            ref={ref}
            className={styles.element}
            style={{ width: animationComplete ? "" : spanWidth * 0.94 + "ch" }}
        >
            {number}
        </span>
    );
};

export default AnimatedCounter;

import React from "react";

import TitleUnderlineParagraph from "../../components/TitleUnderlineParagraph/TitleUnderlineParagraph";
import { cn } from "../../utils";
import * as styles from "./HeroWithForm.module.scss";
import IHeroWithForm from "./HeroWithForm.type";
import loadable from "@loadable/component";

const Form = loadable(() => import("../../components/Form/Form"));

const HeroWithForm = ({
    title,
    pageTitle,
    backgroundColorSection,
    text,
    wpFields,
    ...props
}: IHeroWithForm) => {
    return (
        <section
            className={cn(
                styles.section,
                backgroundColorSection && styles.brand
            )}
        >
            <div className={cn("container", styles.container)}>
                <TitleUnderlineParagraph
                    heading={title === "{Page Title}" ? pageTitle : title}
                    headingLevel="h1"
                    text={text}
                    textSize={"large"}
                    underline={"center"}
                    align="center"
                />
                <Form fields={wpFields} {...props} />
                <div className={cn(styles.bg, styles.leftBg)} />
                <div className={cn(styles.bg, styles.rightBg)} />
            </div>
        </section>
    );
};

export default HeroWithForm;

import "../styles/index.scss";

import { graphql } from "gatsby";
import Seo from "gatsby-plugin-wpgraphql-seo";
import React from "react";

import Layout from "../containers/Layout/Layout";
import getSectionComponent from "../utils/sectionComponentsMap";

interface sectionI {
    fieldGroupName: string;
}

const Page = (props: any) => {
    const { sections } = props.data.wpPage.sections;
    const { pageContext } = props;
    const changeBackground = sections?.find(
        (s: any) => s.changeBackground
    )?.changeBackground;

    return (
        <Layout {...pageContext} changeBackground={changeBackground}>
            <Seo post={props.data.wpPage} />
            {sections
                ?.filter(({ fieldGroupName }: any) => fieldGroupName)
                .map(({ fieldGroupName, ...props }: sectionI, key: number) => {
                    const Section = getSectionComponent(fieldGroupName);
                    return (
                        <Section
                            dataKey={fieldGroupName}
                            key={key}
                            pageTitle={pageContext.title}
                            pageLogo={pageContext.settings.logo}
                            children={pageContext.children}
                            mainMenu={pageContext.mainMenu.nodes}
                            {...props}
                        />
                    );
                })}
        </Layout>
    );
};

export default Page;

export const query = graphql`
    query ($id: String) {
        wpPage(id: { eq: $id }) {
            seo {
                opengraphPublisher
                breadcrumbs {
                    text
                    url
                }
                opengraphPublishedTime
                opengraphModifiedTime
                metaRobotsNofollow
                metaKeywords
                metaDesc
                fullHead
                cornerstone
                canonical
                opengraphTitle
                opengraphSiteName
                opengraphUrl
                opengraphType
                readingTime
                twitterDescription
                title
                twitterTitle
                twitterImage {
                    altText
                    srcSet
                    sourceUrl
                }
                schema {
                    articleType
                    pageType
                    raw
                }
                opengraphAuthor
                metaRobotsNoindex
                opengraphDescription
                opengraphImage {
                    altText
                    sourceUrl
                    srcSet
                }
            }
            sections {
                sections {
                    ... on WpPage_Sections_Sections_HeroWithVideoThumbnailAndTitle {
                        fieldGroupName
                        subtitle
                        title
                        thumbnailVideoId
                        fullWidthVideo
                        popupVideoId
                        thumbnailVideo {
                            mediaItemUrl
                        }
                        videoThumbnail {
                            altText
                            localFile {
                                publicURL
                                childImageSharp {
                                    gatsbyImageData(
                                        transformOptions: { cropFocus: CENTER }
                                        sizes: "(min-width: 1900px) 1900px, 100vw"
                                    )
                                }
                            }
                        }
                        videoThumbnailMobile {
                            altText
                            localFile {
                                publicURL
                                childImageSharp {
                                    gatsbyImageData(
                                        transformOptions: { cropFocus: CENTER }
                                        sizes: "(min-width: 794px) 794px, 100vw"
                                    )
                                }
                            }
                        }
                    }
                    ... on WpPage_Sections_Sections_TwoBoxes {
                        fieldGroupName
                        title
                        description
                        columns {
                            content
                            label
                            title
                        }
                    }
                    ... on WpPage_Sections_Sections_StatsAndCredits {
                        fieldGroupName
                        stats {
                            name
                            counterText
                            counterTarget
                            countFrom
                            icon {
                                altText
                                localFile {
                                    publicURL
                                    childImageSharp {
                                        gatsbyImageData(placeholder: NONE)
                                    }
                                }
                            }
                        }
                        logos {
                            url
                            logo {
                                altText
                                localFile {
                                    childImageSharp {
                                        gatsbyImageData(placeholder: NONE)
                                    }
                                    publicURL
                                }
                            }
                        }
                        chart {
                            topText
                            bottomText
                        }
                        logosTitle
                    }
                    ... on WpPage_Sections_Sections_TestimonialVideos {
                        fieldGroupName
                        videos {
                            name
                            quote
                            videoId
                            thumbnail {
                                altText
                                localFile {
                                    childImageSharp {
                                        gatsbyImageData(
                                            width: 271
                                            height: 365
                                            quality: 100
                                        )
                                    }
                                    publicURL
                                }
                            }
                        }
                    }
                    ... on WpPage_Sections_Sections_TextAndImage {
                        fieldGroupName
                        imageSide
                        text
                        title
                        image {
                            altText
                            localFile {
                                childImageSharp {
                                    gatsbyImageData
                                }
                                publicURL
                            }
                        }
                    }
                    ... on WpPage_Sections_Sections_Countdown {
                        fieldGroupName
                        text
                        date
                        hidden
                        link {
                            url
                            title
                        }
                    }
                    ... on WpPage_Sections_Sections_CallToActionLinks {
                        fieldGroupName
                        links {
                            link {
                                url
                                title
                            }
                            icon {
                                altText
                                localFile {
                                    publicURL
                                }
                            }
                        }
                    }
                    ... on WpPage_Sections_Sections_AdvantagesSlider {
                        fieldGroupName
                        slides {
                            fieldGroupName
                            label
                            upperImage {
                                altText
                                localFile {
                                    childImageSharp {
                                        gatsbyImageData(
                                            aspectRatio: 1
                                            sizes: "214px"
                                            transformOptions: {
                                                cropFocus: CENTER
                                            }
                                            width: 214
                                        )
                                    }
                                    publicURL
                                }
                            }
                            text
                            icon {
                                localFile {
                                    publicURL
                                    childrenImageSharp {
                                        gatsbyImageData
                                    }
                                }
                                altText
                            }
                            lowerImage {
                                altText
                                localFile {
                                    publicURL
                                    childImageSharp {
                                        gatsbyImageData(
                                            aspectRatio: 1
                                            sizes: "150px"
                                            placeholder: NONE
                                        )
                                    }
                                }
                            }
                        }
                    }

                    ... on WpPage_Sections_Sections_PropertiesBoxAndDescription {
                        fieldGroupName
                        heading
                        box {
                            description
                            fieldGroupName
                            heading
                            icon {
                                localFile {
                                    publicURL
                                    size
                                }
                                altText
                                sizes
                            }
                        }
                        text
                        sectionTitle
                        horizontalLayout
                    }
                    ... on WpPage_Sections_Sections_HeroWithForm {
                        caption
                        changeBackground
                        backgroundColorSection
                        defaultSubmitAction
                        emailStructure
                        fieldGroupName
                        submitButtonText
                        defaultTargetEmail
                        defaultHubspotListId
                        text
                        title
                        wpFields {
                            ... on WpPage_Sections_Sections_HeroWithForm_Fields_Input {
                                fieldGroupName
                                label
                                name
                                placeholder
                                required
                                type
                            }
                            ... on WpPage_Sections_Sections_HeroWithForm_Fields_Textarea {
                                fieldGroupName
                                label
                                name
                                placeholder
                                required
                            }
                            ... on WpPage_Sections_Sections_HeroWithForm_Fields_Radio {
                                fieldGroupName
                                label
                                name
                                placeholder
                                required
                                information
                                investWithUsSelected
                                options {
                                    label
                                    value
                                }
                            }
                            ... on WpPage_Sections_Sections_HeroWithForm_Fields_Select {
                                fieldGroupName
                                label
                                placeholder
                                required
                                name
                                options {
                                    hubspotListId
                                    label
                                    submitAction
                                    targetEmail
                                    value
                                }
                            }
                        }
                    }
                    ... on WpPage_Sections_Sections_Investments {
                        fieldGroupName
                        list {
                            description
                            name
                            image {
                                altText
                                localFile {
                                    publicURL
                                    childImageSharp {
                                        gatsbyImageData(
                                            aspectRatio: 1.477
                                            quality: 100
                                            width: 558
                                        )
                                    }
                                }
                            }
                        }
                    }
                    ... on WpPage_Sections_Sections_HeroWithImage {
                        fieldGroupName
                        description
                        investmentNumber
                        rightColumnContent
                        title
                        button {
                            target
                            title
                            url
                        }
                        image {
                            altText
                            localFile {
                                childImageSharp {
                                    gatsbyImageData
                                }
                                publicURL
                            }
                        }
                        logo {
                            altText
                            localFile {
                                childImageSharp {
                                    gatsbyImageData
                                }
                                publicURL
                            }
                        }
                        video {
                            thumbnailVideoId
                            popupVideoId
                            thumbnailVideo {
                                mediaItemUrl
                            }
                            videoThumbnail {
                                altText
                                localFile {
                                    publicURL
                                    childImageSharp {
                                        gatsbyImageData(
                                            transformOptions: { cropFocus: CENTER }
                                            sizes: "(min-width: 1900px) 1900px, 100vw"
                                        )
                                    }
                                }
                            }
                            videoThumbnailMobile {
                                altText
                                localFile {
                                    publicURL
                                    childImageSharp {
                                        gatsbyImageData(
                                            transformOptions: { cropFocus: CENTER }
                                            sizes: "(min-width: 794px) 794px, 100vw"
                                        )
                                    }
                                }
                            }
                        }
                    }
                    ... on WpPage_Sections_Sections_PortfoliosArchiveNew {
                        fieldGroupName
                        pageTitle
                    }
                    ... on WpPage_Sections_Sections_PortfoliosArchive {
                        fieldGroupName
                        pageTitle
                        newInvestment {
                            heading
                            backgroundImage {
                                altText
                                localFile {
                                    publicURL
                                    childImageSharp {
                                        gatsbyImageData
                                    }
                                }
                            }
                            investmentNumber
                            ctaButton {
                                text
                                icon {
                                    altText
                                    localFile {
                                        childImageSharp {
                                            gatsbyImageData
                                        }
                                        publicURL
                                    }
                                }
                            }
                        }
                        wpFields {
                            ... on WpPage_Sections_Sections_PortfoliosArchive_Fields_Select {
                                fieldGroupName
                                label
                                name
                                placeholder
                                required
                                options {
                                    value
                                    targetEmail
                                    submitAction
                                    label
                                    hubspotListId
                                    fieldGroupName
                                }
                            }
                            ... on WpPage_Sections_Sections_PortfoliosArchive_Fields_Textarea {
                                fieldGroupName
                                label
                                name
                                placeholder
                                required
                            }
                            ... on WpPage_Sections_Sections_PortfoliosArchive_Fields_Input {
                                fieldGroupName
                                label
                                name
                                placeholder
                                required
                                type
                            }
                        }
                        submitButtonText
                        caption
                        defaultHubspotListId
                        defaultTargetEmail
                        defaultSubmitAction
                        emailStructure
                        fieldGroupName
                    }
                    ... on WpPage_Sections_Sections_Hero {
                        fieldGroupName
                        text
                        title
                        button {
                            title
                            url
                        }
                        align
                    }
                    ... on WpPage_Sections_Sections_AlternatingGallery {
                        fieldGroupName
                        items {
                            heading
                            text
                            image {
                                altText
                                localFile {
                                    childImageSharp {
                                        gatsbyImageData
                                    }
                                    publicURL
                                }
                            }
                        }
                    }
                    ... on WpPage_Sections_Sections_Partners {
                        fieldGroupName
                        description
                        heading
                        partnerLogos {
                            altText
                            localFile {
                                childImageSharp {
                                    gatsbyImageData
                                }
                                publicURL
                            }
                        }
                    }
                    ... on WpPage_Sections_Sections_TeamMembers {
                        fieldGroupName
                        description
                        heading
                        teamMembers {
                            bio
                            linkedinProfile
                            name
                            portrait {
                                altText
                                localFile {
                                    publicURL
                                    childImageSharp {
                                        gatsbyImageData(aspectRatio: 0.743)
                                    }
                                }
                            }
                            position
                        }
                    }
                    ... on WpPage_Sections_Sections_LegalPage {
                        content
                        pageTitle
                        fieldGroupName
                    }
                    ... on WpPage_Sections_Sections_JobOffers {
                        fieldGroupName
                        jobOffers {
                            jobTitle
                            cityAndState
                            description
                        }
                        formData {
                            targetEmail
                            successTitle
                            successMessage
                            errorMessage
                        }
                    }
                }
                fieldGroupName
            }
        }
    }
`;
